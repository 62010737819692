<script>
import { mapGetters } from 'vuex';

import AppSidebar from '@/components/AppSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';

export default {
  name: 'DashboardLayout',
  data() {
    return {
      sidebarCollapsed: this.$root.sidebarCollapsed || false,
      fullWidth: this.$root.fullWidth || false
    };
  },
  components: {
    AppSidebar,
    AppHeader
  },
  methods: {
    onCollapsedChanged(isCollapsed) {
      this.sidebarCollapsed = isCollapsed;
      this.$root.sidebarCollapsed = isCollapsed;
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser', 'isImpersonating']),
    ...mapGetters('org', ['getOrg']),
    orgLink() {
      return `/admin/org/${this.getOrg.id}`;
    }
  }
};
</script>

<template>
  <div v-if="currentUser" id="dashboard-layout" class="dashboard-layout" :class="{ 'sidebar-collapsed': sidebarCollapsed, 'full-width': fullWidth }">
    <div id="impersonation-warning" v-if="isImpersonating">
      IMPORTANT: You are currently logged as a member of
      <router-link :to="orgLink">{{ getOrg.name }}</router-link>
    </div>
    <app-sidebar :collapsed="sidebarCollapsed" @collapsedChange="onCollapsedChanged" />
    <div class="top-wrapper pb-8">
      <app-header />
      <!-- Element for $modal calls -->
      <v-dialog width="370" />
      <div class="wrapper fadeInUp space-y-8">
        <slot name="banner" />
        <div class="page-title-container">
          <slot name="title" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dashboard-layout {
  position: relative;
  padding-left: 210px;

  #impersonation-warning {
    overflow: visible;
    pointer-events: none;
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 141, 141, 0.26);
    padding: 5px;
    text-align: center;
    color: red;
    z-index: 10000;
    font-size: 18px;

    a {
      pointer-events: auto;
    }
  }

  .app-header {
    left: 210px;
    z-index: 2;
    transition: left 0.2s;
  }

  .app-sidebar {
    z-index: 4;
  }

  &.sidebar-collapsed {
    padding-left: 80px;

    .app-header {
      left: 80px;
    }
  }

  .top-wrapper {
    position: relative;
    min-height: 100vh;
    border-radius: 30px 0 0 30px;
    background: var(--gray-light);
    z-index: 5;

    .wrapper {
      padding: 3px 58px 6px;
      max-width: 1210px;
      margin: auto;

      .page-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ::v-deep .page-title {
          display: flex;
          align-items: center;
          font-size: 32px;
          font-weight: 800;
          line-height: 43px;
          color: var(--primary-color);

          .text-normal {
            font-weight: 600;
          }

          .back-link {
            display: flex;
            align-items: center;
            height: 42px;
            margin-right: 8px;
            color: var(--primary-color);

            .back-arrow {
              padding-right: 8px;
            }

            .back-arrow svg {
              fill: var(--primary-color) !important;
            }

            &:active {
              color: var(--purple-light-3);
            }
          }

          .slash {
            margin-right: 8px;
          }
        }

        .stripe-button {
          background: #fff;
          padding: 8px 18px;
          display: flex;
          justify-content: center;
          align-items: center;

          ::v-deep {
            span:first-of-type {
              margin: 2px 8px 0 0;
            }
          }

          &:hover,
          &:active {
            background-color: var(--gray-light-5);
          }
        }
      }
    }
  }

  &.full-width {
    .top-wrapper .wrapper {
      max-width: unset;
    }
  }

  ::v-deep .panel {
    padding: 28px;
    width: 100%;
    background: #fcfdff;
    box-shadow: var(--box-shadow-blue-lg), 0 36px 15px -19px #0019bc17; // opacity is a bit lighter from the style-guide
    border-radius: 13px;

    h2 {
      margin-bottom: 12px;
      font-size: 22px;
      line-height: 30px;
      font-weight: 800;
      color: var(--primary-color);
    }

    // .panel-title {
    //   padding: 12px 24px;
    //   font-size: 22px;
    //   line-height: 30px;
    //   font-weight: 700;
    //   color: var(--primary-color);
    // }

    .panel-tabs {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 50px;

      a {
        display: inline-block;
        margin-right: 30px;
        color: #bfbfbf;

        &.router-link-exact-active {
        }
      }
    }

    .section-title {
      color: var(--primary-color);
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .form-fields {
      margin-bottom: 50px;

      .field {
        margin-top: 30px;
        width: 220px;

        label {
          font-size: 14px;
          display: block;
          font-weight: 700;
          line-height: 18px;
          margin-bottom: 10px;
        }

        .formulate-input-errors {
          margin: 0;
          padding: 0;
          color: #f56565;
          font-size: 12px;
          list-style-type: none;
        }
      }
    }
  }

  @media (max-width: 800px) {
    ::v-deep {
      padding-left: 176px !important;

      .app-header {
        padding: 16px 26px !important;
      }

      .top-wrapper .wrapper {
        padding: 3px 26px 6px !important;
      }

      .app-sidebar {
        width: 214px !important;

        .siderbar-container {
          padding: 0 6px !important;

          .sidebar-icons a .label {
            margin-left: 6px;
          }
        }
      }
    }
  }
}
</style>
