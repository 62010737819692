<script>
import { mapActions } from 'vuex';

export default {
  name: 'AccountNew',
  props: {
    actionName: String
  },
  data() {
    return {
      newAgentState: '', //'valid' | 'invalid' | 'processing' | 'success' | 'error'
      newAgent: {
        permission: 'agent'
      },
      showPassword: false,
      showPassword2: false,
      formErrorMessages: []
    };
  },

  computed: {
    buttonState() {
      switch (this.newAgentState) {
        case 'valid':
          return 'enabled';
        case 'invalid':
          return 'disabled';
        case 'processing':
          return 'loading';
      }
      return 'disabled';
    }
  },

  watch: {
    newAgent: {
      async handler() {
        this.formErrorMessages = [];
        const isFormValid = !(await this.formHasErrors());
        if (!isFormValid) {
          this.newAgentState = 'invalid';
        } else {
          this.newAgentState = 'valid';
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('user', ['createUser']),
    async formHasErrors() {
      return this.$refs.newAgentForm.hasValidationErrors();
    },
    async createUserClicked() {
      const state = this.newAgentState;
      if (state === 'processing' || state === 'invalid') {
        return;
      }

      try {
        await this.createUser(this.newAgent);
        this.newAgentState = 'success';
        // TODO: different message if it was an update to existing account
        this.$notify({
          text: 'New user was successfully created',
          type: 'success'
        });
        this.$emit('navigate-panel', 'manage');
      } catch (err) {
        this.newAgentState = 'error';
        this.formErrorMessages = [err.message];
      } finally {
        this.newAgentState = 'invalid';
      }
    }
  }
};
</script>

<template>
  <div class="account-new content">
    <formulate-form @submit="createUserClicked" v-model="newAgent" :form-errors="formErrorMessages" ref="newAgentForm" class="form">
      <div class="inner-container space-y-4">
        <div class="title-container">
          <ion-icon name="person" class="ion-icon"></ion-icon>
          <h3 class="title">Account Info</h3>
        </div>
        <formulate-input type="text" validation="required" validation-name="First name" name="firstName" label="First name" placeholder="John" />
        <formulate-input type="text" validation="required" validation-name="Last name" name="lastName" label="Last name" placeholder="Doe" />
        <formulate-input type="email" validation="^required|email" validation-name="Email" name="email" label="Email address" placeholder="John@doe.com" />
        <div class="permission">
          <label>Permission</label>
          <div @click="newAgent.permission = 'agent'" class="select-button">
            <ion-icon v-if="newAgent.permission === 'agent'" name="radio-button-on" class="ion-icon" />
            <ion-icon v-else name="radio-button-off" class="ion-icon" />
            <span>Member</span>
          </div>
          <div @click="newAgent.permission = 'admin'" class="select-button">
            <ion-icon v-if="newAgent.permission === 'admin'" name="radio-button-on" class="ion-icon" />
            <ion-icon v-else name="radio-button-off" class="ion-icon" />
            <span>Admin</span>
          </div>
        </div>
      </div>
      <formulate-errors />
      <div class="panel-footer">
        <button @click="$emit('navigate-panel', 'manage')" type="button" class="button-secondary">Back</button>
        <app-primary-button :state="buttonState" text="Invite" size="md" />
      </div>
    </formulate-form>
  </div>
</template>

<style lang="scss" scoped>
.account-new {
  .form {
    .permission {
      margin: 36px 0;

      label {
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        color: var(--primary-color);
      }

      .select-button {
        display: flex;
        align-items: center;

        .ion-icon {
          margin-right: 6px;
        }
      }
    }

    ::v-deep {
      .formulate-form-errors {
        margin: 0 0 12px 42px;
        margin-bottom: 12px;
      }
    }
  }
}
</style>
