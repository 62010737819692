export default [
  'Animals',
  'Arts, Culture, Humanities',
  'Community Development',
  'Education',
  'Environment',
  'Health',
  'Human and Civil Rights',
  'Human Services',
  'International',
  'Research and Public Policy',
  'Religion',
  'Other'
];
