import Vue from 'vue';
import router from './router';
import quill from 'quill/dist/quill.js';
import VueFormulate from '@braid/vue-formulate';
import VueClipboard from 'vue-clipboard2';
import Notifications from 'vue-notification';
import VModal from 'vue-js-modal';
import ClickOutside from 'vue-click-outside';
import Popover from 'vue-js-popover';
import VueCurrencyFilter from 'vue-currency-filter';
// import VueGtag from 'vue-gtag';

import FormulateVSelectPlugin from '@cone2875/vue-formulate-select';
import 'vue-select/dist/vue-select.css';
import './assets/VueFormulateTheme.scss';
import 'css-tooltip/dist/css-tooltip.min.css';
import 'nprogress/nprogress.css';

import App from './App.vue';
import DashboardLayout from './layouts/DashboardLayout.vue';
import store from './store/store';
import './common';
import './assets/animations.scss';
import './assets/global.scss';
import filters from './filters';

import sentryInitialize from '@/services/sentry';

Vue.use(VueFormulate, {
  plugins: [FormulateVSelectPlugin]
});
Vue.use(VueFormulate);

Vue.use(VueClipboard);
Vue.use(Notifications);
Vue.use(VModal, { dialog: true });
Vue.use(Popover);
// Vue.use(VueGtag, {
//   config: { id: import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID }
// });

Vue.directive('click-outside', ClickOutside);

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];

const currencyConfig = {
  symbol: '',
  thousandsSeparator: ',',
  fractionSeparator: '.',
  symbolPosition: 'front',
  avoidEmptyDecimals: '',
  symbolSpacing: false
};

// 2 Instances: 'currency', currencyRounded
Vue.use(VueCurrencyFilter, [
  { ...currencyConfig, fractionCount: 2 },
  { name: 'currencyRounded', ...currencyConfig, fractionCount: 0 }
]);

Vue.use(filters);
Vue.component('DashboardLayout', DashboardLayout);

const app = new Vue({
  router,
  store,
  render: (h) => h(App)
});

try {
  sentryInitialize(Vue, store, router);
} catch (error) {
  console.log('Error initializing sentry: ', error);
}

window.Quill = quill;
app.$mount('#app');
