import _ from 'lodash';
import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';

NProgress.configure({ showSpinner: false });
import store from '../store/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
    meta: {
      authRequired: true,
      title: 'Dashboard'
    }
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/views/SignUp.vue'),
    meta: {
      title: 'Sign up'
    },
    async beforeEnter(routeTo, routeFrom, next) {
      const currentUser = await store.dispatch('auth/validate');
      if (currentUser && !_.isEmpty(currentUser.org)) {
        return next(_.get(routeTo, 'query.next') || { name: 'home' });
      } else {
        return next();
      }
    }
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => {
      return import('@/views/SignIn.vue');
    },
    meta: {
      title: 'Sign in'
    },
    async beforeEnter(routeTo, routeFrom, next) {
      const currentUser = await store.dispatch('auth/validate');
      if (currentUser) {
        return next(_.get(routeTo, 'query.next') || { name: 'home' });
      } else {
        return next();
      }
    }
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/VerifyEmail.vue'),
    meta: {
      title: 'Verify Email'
    },
    beforeEnter(routeTo, routeFrom, next) {
      const currentUser = store.getters['auth/currentUser'];
      if (_.get(currentUser, 'emailVerified')) {
        next(_.get(routeTo, 'query.next') || { name: 'home' });
      } else {
        next();
      }
    }
  },
  {
    path: '/restore',
    name: 'restore',
    component: () => import('@/views/Restore.vue'),
    meta: {
      title: 'Restore'
    }
  },
  {
    path: '/new-password',
    name: 'newPassword',
    component: () => import('@/views/NewPassword.vue'),
    meta: {
      title: 'New password'
    },
    beforeEnter(routeTo, routeFrom, next) {
      if (!routeTo.query.token) {
        return next({ name: 'sign-in' });
      }
      next();
    }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logout').then(() => next({ name: 'sign-in' })); //! TODO
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => {
      if (store.getters['auth/isCurrentUserFundraiser'] && !store.getters['auth/isCurrentUserSuperuser']) {
        return import('@/views/DashboardFundraiser.vue');
      }
      return import('@/views/Dashboard.vue');
    },
    meta: {
      authRequired: true,
      title: 'Dashboard'
    }
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/views/Campaigns/Index.vue'),
    meta: {
      authRequired: true,
      title: 'Campaigns'
    }
  },
  {
    path: '/campaigns/:id',
    component: () => import('@/views/Campaigns/Show.vue'),
    children: [
      { path: '', name: 'campaign', component: () => import('@/views/Campaigns/Checkout.vue') },
      { path: 'installation', name: 'campaign-installation', component: () => import('@/views/Campaigns/Installation.vue') },
      { path: 'emails', name: 'campaign-emails', component: () => import('@/views/Campaigns/Emails.vue') }
    ],
    meta: {
      authRequired: true,
      title: 'Campaign'
    }
  },
  {
    path: '/charges',
    name: 'charges',
    component: () => import('@/views/Charges/Index.vue'),
    meta: {
      authRequired: true,
      title: 'All transactions'
    }
  },
  {
    path: '/charges/:id',
    name: 'charge',
    component: () => import('@/views/Charges/Show.vue'),
    meta: {
      authRequired: true,
      title: 'Transaction'
    }
  },
  {
    path: '/donations',
    name: 'donations',
    component: () => import('@/views/Recurring/Index.vue'),
    meta: {
      authRequired: true,
      title: 'Recurring'
    }
  },
  {
    path: '/donations/:id',
    name: 'donation',
    component: () => import('@/views/Recurring/Show.vue'),
    meta: {
      authRequired: true,
      title: 'Recurring'
    }
  },

  {
    path: '/donors',
    name: 'donors',
    component: () => import('@/views/Donors.vue'),
    meta: {
      authRequired: true,
      title: 'Donors'
    }
  },
  {
    path: '/donors/:id',
    name: 'donor',
    component: () => import('@/views/Donor.vue'),
    meta: {
      authRequired: true,
      title: 'Donor'
    }
  },
  {
    path: '/fundraisers',
    name: 'fundraisers',
    component: () => import('@/views/Fundraisers/FundraisersPage.vue'),
    redirect: { name: 'fundraisers-active' },
    children: [
      { path: '', name: 'fundraisers-active', component: () => import('@/views/Fundraisers/Index.vue') },
      { path: 'pending', name: 'fundraisers-pending', component: () => import('@/views/Fundraisers/Index.vue') }
    ],
    meta: {
      authRequired: true,
      title: 'Fundraisers'
    }
  },
  {
    path: '/fundraisers/new',
    name: 'fundraiser-new',
    component: () => import('@/views/Fundraisers/Create.vue'),
    meta: {
      authRequired: true,
      title: 'Add New Fundraiser'
    }
  },
  {
    path: '/fundraisers/:id',
    name: 'fundraiser',
    component: () => import('@/views/Fundraisers/Show.vue'),
    meta: {
      authRequired: true,
      title: 'Fundraiser'
    }
  },
  {
    path: '/settings',
    // name: 'settings',
    component: () => import('@/views/Settings/Index.vue'),
    children: [
      // { path: '', name: 'settings-account', component: () => import('@/views/settings/Account.vue') },
      { path: '', name: 'settings-integrations', component: () => import('@/views/Settings/SettingsIntegrations.vue') },
      { path: 'installation', name: 'settings-installation', component: () => import('@/views/Settings/SettingsInstallation.vue') }
    ],
    meta: {
      authRequired: true,
      title: 'Settings'
    },

    beforeEnter(routeTo, routeFrom, next) {
      const isAdmin = store.getters['auth/isCurrentUserAdmin'];
      if (!isAdmin) {
        next('home');
      } else {
        next();
      }
    }
  },
  {
    path: '/settings/integrations/hubspot',
    name: 'settings-integrations-hubspot',
    component: () => import('@/views/Settings/integrations/hubspot/Hubspot.vue'),
    meta: {
      authRequired: true,
      title: 'HubSpot'
    },
    beforeEnter(routeTo, routeFrom, next) {
      const isAdmin = store.getters['auth/isCurrentUserAdmin'];
      if (!isAdmin) {
        next('home');
      } else {
        next();
      }
    }
  },
  {
    path: '/admin',
    component: () => import('@/views/Admin.vue'),
    children: [
      { path: '', name: 'admin-organizations', component: () => import('@/views/Admin/Organizations.vue') },
      { path: 'donation-drafts', name: 'admin-donation-drafts', component: () => import('@/views/Admin/DonationDrafts.vue') }
    ],
    meta: {
      authRequired: true,
      title: 'Admin'
    },
    beforeEnter(routeTo, routeFrom, next) {
      const isSuperuser = store.getters['auth/isCurrentUserSuperuser'];
      if (!isSuperuser) {
        next('home');
      } else {
        next();
      }
    }
  },
  {
    path: '/admin/org/:id',
    name: 'admin-org',
    component: () => import('@/views/Orgs/Org.vue'),
    children: [
      { path: '', name: 'org-donations', component: () => import('@/views/Orgs/OrgDonations.vue') },
      { path: 'donors', name: 'org-donors', component: () => import('@/views/Orgs/OrgDonors.vue') },
      { path: 'agents', name: 'org-agents', component: () => import('@/views/Orgs/OrgAgents.vue') },
      { path: 'admin-settings', name: 'org-crm-settings', component: () => import('@/views/Orgs/OrgCrmSettings.vue') },
      { path: 'installation', name: 'org-installation', component: () => import('@/views/Orgs/OrgInstallation.vue') }
    ],
    meta: {
      authRequired: true,
      title: 'Organization'
    }
  },
  {
    path: '*',
    name: 'otherwise',
    redirect: '/dashboard',
    meta: {
      authRequired: true,
      title: 'Dashboard'
    }
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: import.meta.env.BASE_URL,
  routes
});

router.beforeEach(async (routeTo, routeFrom, next) => {
  if (routeTo.name) {
    NProgress.start();
  }

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  function redirectToSignIn() {
    const nextPath = routeTo.fullPath.replace(/^\//, '');
    const query = nextPath && nextPath !== 'dashboard' ? { next: nextPath } : undefined;
    return next({ name: 'sign-in', query });
  }

  function redirectToSignUp() {
    const nextPath = routeTo.fullPath.replace(/^\//, '');
    const query = nextPath && nextPath !== 'dashboard' ? { next: nextPath } : undefined;
    return next({ name: 'sign-up', query });
  }

  if (!authRequired) {
    return next();
  }

  const currentUser = await store.dispatch('auth/validate');

  if (!currentUser) {
    return redirectToSignIn();
  }

  const userHasOrg = !_.isEmpty(currentUser.org);
  if (!userHasOrg) {
    return redirectToSignUp();
  }

  // User is authenticated, and has an org
  return next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
