import * as Sentry from '@sentry/vue';
import request from '../../services/request';
import _ from 'lodash';

export const state = {
  currentUser: {},
  isIntercomRegistered: false
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    Sentry.setUser(_.pick(newValue, ['id', 'email', 'firstName', 'lastName']));
    state.currentUser = newValue;
  },
  SET_IS_INTERCOM_REGISTERED(state, newValue) {
    state.isIntercomRegistered = newValue;
  }
};

export const getters = {
  currentUser(state) {
    return state.currentUser;
  },
  isImpersonating(state) {
    return state.currentUser.org?.user_org.isImpersonating;
  },
  loggedIn(state) {
    return !!state.currentUser;
  },
  userPermission(state) {
    if (!state.currentUser) {
      return false;
    }
    // TODO: Sometimes user_org is not set in org
    return state.currentUser.org?.user_org.permission;
  },
  isCurrentUserSuperuser(state) {
    return state.currentUser.permission === 'superuser';
  },
  isCurrentUserAgent(state, getters) {
    const agentRules = ['owner', 'admin', 'agent'];
    return agentRules.includes(getters.userPermission) || getters.isCurrentUserSuperuser;
  },
  isCurrentUserAdmin(state, getters) {
    const adminRules = ['owner', 'admin'];
    return adminRules.includes(getters.userPermission) || getters.isCurrentUserSuperuser;
  },
  isCurrentUserOwner(state, getters) {
    return getters.userPermission === 'owner';
  },
  isCurrentUserFundraiser(state, getters) {
    return getters.userPermission === 'fundraiser';
  },
  currentUserId(state) {
    return state.currentUser.id;
  }
};

export const actions = {
  setCurrentUser({ commit }, user) {
    commit('SET_CURRENT_USER', user);
    // dispatch('registerIntercom', !!user);
  },

  async signIn({ dispatch }, { email, password, orgId } = {}) {
    const { data, error } = await request.post(`/sign-in?orgId=${orgId || ''}`, { email, password });
    if (error) {
      throw new Error(data);
    } else {
      const user = data;
      dispatch('setCurrentUser', user);
      return user;
    }
  },

  async logout({ dispatch }) {
    const { data, error } = await request('/logout');
    if (error) {
      throw new Error(data);
    } else if (data === 'disconnected') {
      dispatch('setCurrentUser', null);
    }
  },

  async validate({ state, commit, dispatch }) {
    if (!state.currentUser) {
      return null;
    }
    const { data, error } = await request.get('is-authenticated');
    if (error) {
      if (error.code === 403 || error.code === 401) {
        dispatch('setCurrentUser', null);
      } else {
        console.warn(error);
      }
    } else {
      const user = data;
      dispatch('setCurrentUser', user);
      // TODO: should delete org object from user?
      commit('org/SET_CURRENT_ORG', user.org, { root: true });
      return user;
    }
  },

  async restorePassword(context, { email } = {}) {
    const { data, error } = await request.post('/public/restore-password', { email });
    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async newPassword(context, { token, password, type } = {}) {
    const { data, error } = await request.post('/public/new-password', { token, password, type });
    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async checkRestoreToken(context, { token, type } = {}) {
    const { data, error } = await request(`/public/check-restore-token/${token}/${type}`);
    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async checkRegisteredEmail(context, { email }) {
    const { data, error } = await request.post('/sign-up/check-registered', { email });
    if (error) {
      throw new Error(data);
    } else {
      return data;
    }
  },

  async verifyEmail(context, { token }) {
    const { data, error } = await request.post('/public/verify-email', { token });
    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async sendVerificationEmail(context, { emailAddress }) {
    if (!emailAddress) {
      throw new Error('Email address is missing');
    }
    const { data, error } = await request.post('/public/send-verification-email', { emailAddress });
    if (error) {
      throw new Error(data);
    }
    return data;
  },

  registerIntercom({ state, commit }, shouldRegister) {
    if (!shouldRegister) {
      // hide user details on logout
      window.Intercom('update', { name: null, email: null, user_id: null });
      return commit('SET_IS_INTERCOM_REGISTERED', false);
    }

    const user = state.currentUser;
    if (state.isIntercomRegistered) {
      return window.Intercom('update', {
        name: `${user.firstName || ''} ${user.lastName || ''}`,
        email: user.email,
        user_id: user.id
      });
    }

    // window.LogRocket && window.LogRocket.identify(state.currentUser.id, {
    //   name: state.currentUser.firstName + ' ' + state.currentUser.lastName,
    //   email: state.currentUser.email,
    //   organization: _.get(state.currentUser, 'org.name')
    // });

    window.Intercom('boot', {
      app_id: 'd98x8vl4',
      name: `${user.firstName || ''} ${user.lastName || ''}`,
      email: user.email,
      user_id: user.id
    });
    commit('SET_IS_INTERCOM_REGISTERED', true);
  }
};
