import store from './store/store';

const numberFilter = (value, decimals = 0) => {
  const float = parseFloat(value);
  return Number.isNaN(float) ? '' : float.toLocaleString('en', { minimumFractionDigits: decimals });
};

const capitalizeFilter = (value) => {
  if (!value) {
    return '';
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const dateLocalizedFilter = (date, additionalOptions) => {
  if (!date) {
    return 'n/a';
  }

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };

  return new Date(date).toLocaleDateString(undefined, {
    ...options,
    ...(additionalOptions && additionalOptions)
  });
};

const orgCurrency = (value) => {
  if (!value) {
    value = 0;
  }
  return store.getters['org/getOrgCurrencySymbol'] + value;
};

export default {
  install(app) {
    app.filter('number', numberFilter);
    app.filter('capitalize', capitalizeFilter);
    app.filter('dateLocalized', dateLocalizedFilter);
    app.filter('orgCurrency', orgCurrency);
  }
};
