<script>
export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const title = to.meta.title;
        const documentTitle = `Double ${title ? '- ' : ''}${title || ''}`;
        document.title = documentTitle;
      }
    }
  }
};
</script>

<template>
  <div id="app">
    <router-view />
    <notifications position="bottom left" />
  </div>
</template>
