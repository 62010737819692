import axios from 'axios';
import * as Sentry from '@sentry/vue';

import request from '../../services/request';
import utils from '@/utils';

export const state = {
  currentOrg: {},
  defaultCampaign: {}
};

export const mutations = {
  SET_CURRENT_ORG(state, newValue) {
    if (!newValue) {
      return;
    }
    Sentry.setTag('org', newValue.name);
    state.currentOrg = newValue;
  },

  SET_ORG_CRM_SETTINGS(state, newValue) {
    state.currentOrg.crmSettings = newValue;
  },

  SET_ORG_HUBSPOT_CONNECTION(state, newValue) {
    state.currentOrg.hubspotConnection = newValue;
  }
};

export const getters = {
  getOrg(state) {
    return state.currentOrg;
  },
  getOrgId(state) {
    return state.currentOrg.id;
  },
  getOrgCurrency(state) {
    return state.currentOrg.currency;
  },
  getOrgCurrencySymbol(state) {
    return utils.getCurrencySymbol(state.currentOrg.currency);
  }
  // completedTourSteps (state) {
  //   return state.currentOrg.crmSettings.completedTourSteps || {};
  // },
  // isAllTourStepsCompleted (state, getters) {
  //   const steps = ['customization', 'integration', 'installation'];
  //   const completedSteps = Object.keys(getters.completedTourSteps); // If key exists it should always be true
  //   const isAllTourStepsCompleted = steps.every((step) => completedSteps.includes(step));
  //   return isAllTourStepsCompleted;
  // }
};

export const actions = {
  async createOrg(_, { userFields, orgFields }) {
    const { data, error } = await request.post('/sign-up', { userFields, orgFields });

    if (error) {
      throw new Error(data);
    } else {
      return data;
    }
  },

  async saveOrg({ commit, state }, { org, orgLogo, deleteLogo = false, orgId = state.currentOrg.id }) {
    if (!orgId) {
      throw new Error('org id is missing');
    }

    const bodyFormData = new FormData();
    bodyFormData.append('org', JSON.stringify(org));
    bodyFormData.append('orgLogo', orgLogo);
    if (deleteLogo) {
      bodyFormData.append('deleteLogo', deleteLogo);
    }

    const { data, error } = await axios({
      method: 'put',
      url: `${import.meta.env.VITE_APP_SERVER_URL}/org/${orgId}`,
      data: bodyFormData,
      withCredentials: true,
      'Content-Type': 'multipart/form-data'
    });

    if (error) {
      throw new Error(data);
    } else {
      const updatedOrg = data.data;
      const isCurrentOrg = orgId === state.currentOrg.id;
      if (isCurrentOrg) {
        // Is not current org (superuser)
        commit('SET_CURRENT_ORG', updatedOrg);
      }
      return updatedOrg;
    }
  },

  async saveOrgSettings({ commit }, { settings }) {
    const { data, error } = await request.post(`/org/settings`, { settings });
    if (error) {
      throw new Error(data);
    }

    commit('SET_CURRENT_ORG', data);
    return data;
  },

  async saveOrgCrmSettings({ commit, state }, { crmSettings, feePercentage, orgId = state.currentOrg.id }) {
    if (!orgId) {
      throw new Error('org id is missing');
    }
    const { data, error } = await request.post(`admin/org/${orgId}/crm-settings`, { crmSettings, feePercentage });
    if (error) {
      throw new Error(data);
    } else {
      const updatedOrg = data;
      commit('SET_CURRENT_ORG', updatedOrg);
      return updatedOrg;
    }
  },

  async fetchOrg({ state }, orgId = state.currentOrg.id) {
    const { data, error } = await request.get(`/org/${orgId}`);
    if (error) {
      throw new Error(data);
    }

    return data;
  },

  async fetchCampaign(_, campaignId) {
    const { data, error } = await request.get(`/campaign/${campaignId}`);

    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async saveCampaign(_, { campaignId, campaign }) {
    const { data, error } = await request.post(`/campaign/${campaignId}`, { campaign });

    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async fetchOrgStripeData({ state }, orgId = state.currentOrg.id) {
    const { data, error } = await request.get(`/org/${orgId}/stripe-data`);
    if (error) {
      throw new Error(data);
    }

    return data;
  },

  async saveCheckoutSettings(_, { settings, campaignId }) {
    const { data, error } = await request.post(`/org/checkout-settings?campaignId=${campaignId}`, { settings });
    if (error) {
      throw new Error(data);
    }

    return data;
  },

  // todo: per checkout_settings
  async getDonationDesignationsInUse(_, orgId = state.currentOrg.id) {
    const { data, error } = await request.get(`/org/${orgId}/designations`);
    if (error) {
      throw new Error(data);
    }

    return data.map((designation) => designation.designation) || [];
  },

  async changeCurrentOrg(_, { id }) {
    const { data, error } = await request.post('/org/current-org', { orgId: id });
    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async verifyPaymentDomain(_, { domain }, orgId = state.currentOrg.id) {
    const { data } = await request.post(`/org/${orgId}/payment-domain`, { domain });

    return data;
  },

  async chariotIntegrate({ commit }, { email, ein }) {
    const { data, error } = await request.post(`/org/chariot`, { email, ein });
    commit('SET_CURRENT_ORG', data);

    if (error) {
      throw new Error(data);
    }

    return data;
  },

  async chariotDisconnect({ commit }) {
    const { data, error } = await request.delete(`/org/chariot`);
    commit('SET_CURRENT_ORG', data);

    if (error) {
      throw new Error(data);
    }

    return data;
  }
};
