import _ from 'lodash';
import router from '../../router';
import request from '../../services/request';

const WELCOME_TOUR_STEPS = [
  { name: 'customization', path: 'settings-widget' },
  { name: 'integration', path: 'settings-integrations' },
  { name: 'installation', path: 'settings-installation' }
];

export const state = {
  activeStep: null
};

export const mutations = {
  SET_ACTIVE_STEP(state, newValue) {
    state.activeStep = newValue;
  }
};

export const getters = {
  completedTourSteps(state, getters, rootState) {
    return _.get(rootState.org.currentOrg, 'crmSettings.completedTourSteps', {});
  },
  isAllTourStepsCompleted(state, getters) {
    const steps = WELCOME_TOUR_STEPS.map((step) => step.name);
    const completedTourStepKeys = Object.keys(getters.completedTourSteps); // If key exists it should always be true
    return steps.every((step) => completedTourStepKeys.includes(step));
  },
  leftSteps(state, getters) {
    if (getters.currentStepIndex < 0) {
      return WELCOME_TOUR_STEPS;
    }
    return [...WELCOME_TOUR_STEPS].splice(getters.currentStepIndex + 1, WELCOME_TOUR_STEPS.length);
  },
  nextStep(state, getters) {
    return getters.leftSteps.find((step) => !getters.completedTourSteps[step.name]);
  },
  currentStepIndex(state) {
    return WELCOME_TOUR_STEPS.map((step) => step.name).indexOf(state.activeStep);
  },
  isLastStep(state, getters) {
    if (getters.currentStepIndex < 0) {
      return false;
    }
    return !getters.nextStep;
  }
};

export const actions = {
  goToNextStep({ commit, getters }) {
    if (!getters.nextStep || getters.isLastStep) {
      return commit('SET_ACTIVE_STEP', null);
    }

    const { name, path } = getters.nextStep;
    commit('SET_ACTIVE_STEP', name);
    router.push({ name: path });
  },

  async saveCompletedTourStep({ commit, dispatch, getters, rootState }, tourStep) {
    const completedTourStepKeys = Object.keys(getters.completedTourSteps);
    if (completedTourStepKeys.includes(tourStep)) {
      return;
    }

    const orgId = rootState.org.currentOrg.id;
    if (!orgId) {
      throw new Error('org id is missing');
    }
    const completedTourSteps = { ...getters.completedTourSteps, [tourStep]: true };
    const { data, error } = await request.post(`/org/${orgId}/completed-tour-steps`, { completedTourSteps });
    if (error) {
      throw new Error(data);
    } else {
      commit('org/SET_ORG_CRM_SETTINGS', data, { root: true });
      dispatch('goToNextStep');
      return data;
    }
  }
};
