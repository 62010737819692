<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('org', ['currentOrg']),
    ...mapGetters('auth', ['isCurrentUserAdmin']),
    orgName() {
      return this.currentOrg.name;
    },
    orgsSortedFiltered() {
      const otherOrgs = this.currentUser.orgs?.filter((org) => org.id !== this.currentUser.orgId);
      return otherOrgs;
    }
  },

  methods: {
    ...mapActions('org', ['changeCurrentOrg']),
    async changeCurrentOrgClicked(orgId) {
      if (orgId === this.currentUser.org.id) {
        return;
      }
      await this.changeCurrentOrg({ id: orgId });
      if (this.$route.name !== 'dashboard') {
        // dashboard is available for all permission roles
        await this.$router.push({ name: 'dashboard' });
      }
      window.location.reload();
    },

    // UI
    logoPath(orgId = null) {
      const org = this.getUserOrgById(orgId);
      const logoName = org.crmSettings.logoName;
      if (!logoName) {
        return;
      }
      return `${import.meta.env.VITE_APP_SERVER_URL}/public-files/org/${orgId || this.currentOrg.id}/${org.crmSettings.logoName}`;
    },
    getUserOrgById(id) {
      if (!id) {
        return this.currentOrg;
      }
      return id === this.currentOrg.id ? this.currentOrg : this.currentUser.orgs.find((org) => org.id === id);
    },
    hasLogo(orgId) {
      if (!orgId) {
        return !!this.currentOrg?.crmSettings?.logoName;
      }
      const org = this.getUserOrgById(orgId);
      return !!org.crmSettings.logoName;
    }
  }
};
</script>

<template>
  <popover name="account" v-if="currentUser">
    <div class="popover-header">
      <div class="org-logo-background">
        <img v-if="hasLogo(currentOrg.id)" :src="logoPath()" alt="Logo" class="org-logo" />
        <img v-else src="@/assets/org-logo-placeholder.png" class="org-logo-placeholder" />
      </div>
      <div class="current-org-name">{{ orgName }}</div>
      <app-icon
        v-if="isCurrentUserAdmin"
        @click.native="$emit('open-manager-panel')"
        height="16"
        width="16"
        type="svg"
        name="settingsIcon"
        class="org-list-item-icon"
      />
      <span v-else class="org-list-item-icon"></span>
    </div>

    <div @click="changeCurrentOrgClicked(org.id)" v-for="org in orgsSortedFiltered" :key="org.id">
      <div class="org-list-item">
        <div class="org-logo-background">
          <img v-if="hasLogo(org.id)" :src="logoPath(org.id)" alt="Logo" class="org-logo" />
          <img v-else src="@/assets/org-logo-placeholder.png" class="org-logo-placeholder" />
        </div>
        <span class="org-name">{{ org.name }}</span>
        <ion-icon name="arrow-forward-outline" class="org-list-item-icon"></ion-icon>
        <!-- <div class="test">t</div> -->
      </div>
    </div>
    <hr class="divider" />
    <div class="popover-body">
      <div class="profile-details">
        <h3 class="name">{{ currentUser.firstName }} {{ currentUser.lastName }}</h3>
        <h5 class="email">{{ currentUser.email }}</h5>
      </div>
      <div class="account-buttons flex justify-between items-center">
        <div class="w-1/2 flex">
          <app-primary-button
            @click="
              $emit('open-edit-panel');
              shouldShowBackButton = false;
            "
            text="Edit User"
            size="md"
            full
          />
        </div>

        <router-link to="/logout" class="text-center w-1/2 text-xs leading-8 text-primary focus:outline-none">Sign out</router-link>
      </div>
    </div>
  </popover>
</template>

<style lang="scss" scoped>
[data-popover='account'] {
  padding: 0;
  right: 56px !important;
  left: unset !important;
  margin-top: 18px;
  width: 260px !important;
  max-width: 260px !important;
  border-radius: 12px;
  box-shadow: 0 20px 40px #071c4e33;
  -webkit-animation: slideUp 0.2s ease;
  animation: slideUp 0.2s ease;

  &::before {
    visibility: hidden;
  }

  .org-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    color: var(--primary-color);
  }

  .org-logo-background {
    margin-right: 8px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    width: 34px;
    height: 34px;
    background: #fff;
    .org-logo {
      height: 34px;
      width: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      padding: 4px;
      object-fit: cover;
    }

    .org-logo-placeholder {
      border-radius: 12px;
      color: var(--purple-light);
      margin: auto;
      padding: 2px;
      display: flex;
      align-items: center;
      width: 30px;
      height: 30px;
    }
  }

  .popover-header {
    height: 52px;
    padding: 8px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background: var(--primary-color-gradient);
    border-radius: 12px 12px 0 0;
    box-shadow: 0 5px 14px #001ddb40;

    .org-name {
      color: #fff;
    }

    .org-list-item-icon {
      margin-left: auto;
      ::v-deep {
        cursor: pointer;
        transition: 0.2s ease-out;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .org-list-item {
    padding: 8px 26px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.15s ease-out;

    &:hover {
      background-color: #f5f7fc;
      ion-icon[name='arrow-forward-outline'] {
        visibility: visible;
      }
    }

    ion-icon[name='arrow-forward-outline'] {
      margin-left: auto;
      visibility: hidden;
      color: var(--primary-color);
      font-size: 22px;
    }
  }

  .divider {
    margin: 0;
    border: 0;
    border-top: 1px solid #eff2fb;
  }

  .popover-body {
    padding: 21px 30px;

    .profile-details {
      .name,
      .email {
        margin: 0;
        font-weight: 600;
      }

      .name {
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-color);
      }

      .email {
        font-size: 13px;
        line-height: 18px;
        color: var(--purple-light-3);
      }
    }

    .account-buttons {
      margin-top: 14px;
    }
  }
}
</style>
