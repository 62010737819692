<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppSidebar',
  props: { collapsed: Boolean },
  computed: {
    ...mapGetters('auth', ['isCurrentUserSuperuser', 'isCurrentUserAdmin', 'isCurrentUserAgent'])
  },
  methods: {
    toggleSideBarCollapsed() {
      this.$emit('collapsedChange', !this.collapsed);
    }
  }
};
</script>

<template>
  <div class="app-sidebar" :class="{ collapsed: collapsed }">
    <div class="siderbar-header"></div>
    <div class="siderbar-container">
      <div class="logo">
        <router-link to="/">
          <double-logo theme="light" />
        </router-link>
      </div>
      <div class="sidebar-icons">
        <router-link v-if="isCurrentUserAgent" :to="{ name: 'dashboard' }">
          <app-icon type="svg" name="dashboard" />
          <span class="label">Dashboard</span>
        </router-link>
        <router-link v-if="isCurrentUserAgent" :to="{ name: 'campaigns' }">
          <ion-icon name="megaphone" class="app-icon w-6"></ion-icon>
          <span class="label">Campaigns</span>
        </router-link>

        <router-link v-if="isCurrentUserAgent" :to="{ name: 'charges' }">
          <app-icon type="svg" name="card" />
          <span class="label">Transactions</span>
        </router-link>
        <router-link v-if="isCurrentUserAgent" :to="{ name: 'donations' }">
          <app-icon type="svg" name="rotate" />
          <span class="label">Recurring</span>
        </router-link>
        <router-link v-if="isCurrentUserAgent" :to="{ name: 'donors' }">
          <app-icon type="font" name="people" />
          <span class="label">Donors</span>
        </router-link>
        <router-link v-if="isCurrentUserAdmin" :to="{ name: 'fundraisers' }">
          <app-icon type="font" name="people" />
          <span class="label">Fundraisers</span>
        </router-link>
        <router-link v-if="isCurrentUserAdmin" :to="{ name: 'settings-integrations' }">
          <app-icon type="svg" name="settings" />
          <span class="label">Settings</span>
        </router-link>
        <router-link v-if="isCurrentUserSuperuser" to="/admin" class="superuser-link">
          <app-icon type="svg" name="key" />
          <span class="label">Admin</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  margin-top: 38px;
  margin-right: auto;

  ::v-deep {
    svg {
      width: 90px;
    }
  }
}

.app-sidebar {
  position: fixed;
  width: 234px;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: transparent linear-gradient(166deg, var(--primary-color) 0%, #3342b1 57%, #5669ef 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  min-height: 450px;
  text-align: left;
  transition: width 0.2s;

  .siderbar-header {
    user-select: none;

    .toggle-siderbar {
      display: flex;
      height: 80px;
      align-items: center;
      justify-content: flex-start;
      padding: 0 30px;
      font-size: 20px;
      font-weight: 700;

      .app-icon {
        width: 24px;
        min-width: 24px;
        text-align: center;
        cursor: pointer;
      }

      .label {
        flex: 100%;
        margin-left: 20px;
        overflow: hidden;
        transition: flex 0.2s, margin 0.2s;
      }
    }
  }

  .siderbar-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 26px;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    user-select: none;
    direction: rtl; // move scrollbar to left-side

    .sidebar-icons {
      direction: ltr; // fix the direction after moving the scrollbar
      margin-top: 92px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;

      ::v-deep {
        a {
          display: flex;
          height: 38px;
          margin: 0 0 16px;
          justify-content: flex-start;
          align-items: center;
          color: var(--blue-light);
          border-radius: 5px;
          cursor: pointer;
          text-decoration: none;
          transition: 0.2s;

          &.superuser-link {
            margin-top: auto;
          }

          .app-icon {
            padding: 10px;
            font-size: 18px;
          }

          .svg-icon {
            svg {
              max-width: 18px;
              max-height: 18px;
            }

            g,
            path {
              fill: var(--blue-light);
              stroke: transparent;
            }
          }

          .label {
            font-size: 18px;
            line-height: 32px;
            font-weight: 600;
            flex: 100%;
            margin-left: 12px;
            overflow: hidden;
            transition: flex 0.2s, margin 0.2s;
          }

          &.router-link-active {
            color: white;

            .label {
              font-weight: 700;
            }

            .app-icon {
              background-color: var(--red);
              border-radius: 10px;
              box-shadow: 0 6px 15px #00006c80;
            }

            .svg-icon {
              g,
              path {
                fill: #fff;
                stroke: transparent;
              }
            }
          }

          &:hover:not(.router-link-active) {
            color: var(--blue-light-2);

            .app-icon {
              border-radius: 10px;
              background: var(--blue-dark);
            }

            .svg-icon {
              g,
              path {
                fill: var(--blue-light-2);
                stroke: transparent;
              }
            }
          }
        }
      }
    }
  }

  &.collapsed {
    width: 80px;

    .siderbar-header {
      .toggle-siderbar {
        .label {
          flex: 0;
          margin-left: 0;
        }
      }
    }

    .siderbar-container {
      a {
        padding: 0 8px;

        .label {
          flex: 0;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
