import * as Sentry from '@sentry/vue';

export default function (vue, store, router) {
  Sentry.init({
    Vue: vue,
    dsn: import.meta.env.VITE_APP_SENTRY_URL,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracePropagationTargets: [/^https:\/\/api.*\.double\.giving/],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: import.meta.env.VITE_RELEASE
  });

  Sentry.setContext({ store: store.state });
}
