<script>
export default {
  name: 'AccountEdit',
  props: {
    actionName: String
  }
};
</script>

<template>
  <section class="account-control-panel">
    <div class="panel-header">
      <div class="close-icon" @click="$emit('close-panel')">
        <app-icon type="font" name="close-outline" />
      </div>
      <h1 class="action-name">{{ actionName }}</h1>
    </div>
    <div class="wrapper">
      <slot />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.account-control-panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 424px;
  color: var(--primary-color);
  background: var(--white);
  border-radius: 25px 0 0 25px;
  box-shadow: var(--box-shadow-blue-lg);
  overflow-y: auto;
  scrollbar-width: thin; // firefox
  z-index: 10;

  .panel-header {
    padding: 56px 48px 12px;

    .close-icon {
      float: right;
      font-size: 26px;
      cursor: pointer;
    }

    .action-name {
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
    }
  }

  ::v-deep {
    .title-container {
      display: flex;
      align-items: center;

      .icon {
        color: var(--red);
      }

      .title {
        margin: 0 8px 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
        color: var(--primary-color);
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      .inner-container {
        width: 90%;
        padding: 32px 48px;
        flex-grow: 1;
        overflow-y: auto;
        scrollbar-width: thin; // firefox
        height: calc(100vh - 224px); // panel-header, panel-footer
      }

      .form {
        input {
          width: 100%;
        }
      }
    }

    .panel-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 110px;
      padding: 28px 42px;
      box-shadow: var(--box-shadow-blue-xl);
    }
  }
}
</style>
