<script>
import { mapGetters, mapState } from 'vuex';

import AccountControlPanel from '@/components/account/AccountControlPanel.vue';
import AccountManage from '@/components/account/AccountManage.vue';
import AccountEdit from '@/components/account/AccountEdit.vue';
import AccountNew from '@/components/account/AccountNew.vue';
import AccountPopover from '@/components/account/AccountPopover.vue';

export default {
  name: 'FormHeader',
  components: {
    AccountControlPanel,
    AccountManage,
    AccountEdit,
    AccountNew,
    AccountPopover
  },
  data() {
    return {
      openPanelString: null, //'new' | 'edit' | 'manage' | 'new'
      shouldShowBackButton: true,
      activeTabString: '',
      showOverlay: false,
      componentKey: 0
    };
  },

  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapGetters('auth', ['isCurrentUserAgent'])
  },

  methods: {
    openPanel(panelString, activeTabString) {
      const isValidPanelString = ['manage', 'edit', 'new'].includes(panelString);
      if (!isValidPanelString) {
        throw new Error('invalid panel string');
      }
      this.openPanelString = panelString;
      if (activeTabString) {
        this.activeTabString = activeTabString;
      }

      this.$emit('toggle-panel', 'opened');
      this.$root.$emit('bv::hide::popover');
    },
    openManagerPanel() {
      this.openPanel('manage');
      this.activeTabString = 'orgInfo';
    },
    closePanel() {
      this.openPanelString = null;
      this.$emit('toggle-panel', 'closed');
    },
    openAccountEdit() {
      this.openPanelString = 'edit';
      this.shouldShowBackButton = true;
    },
    openAccountNew() {
      this.openPanelString = 'new';
    }
  }
};
</script>

<template>
  <header class="app-header">
    <div :class="{ overlay: openPanelString }"></div>
    <nav class="nav">
      <transition name="account-panel-slide">
        <account-control-panel v-if="openPanelString === 'manage'" @close-panel="closePanel" action-name="Organization Manager">
          <account-manage
            @navigate-panel="openPanel($event)"
            @account-edit="openAccountEdit"
            @remount-component="componentKey++"
            :key="componentKey"
            :active-tab-prop="activeTabString"
          />
        </account-control-panel>
        <account-control-panel v-if="openPanelString === 'edit'" @close-panel="closePanel" action-name="Edit User">
          <account-edit @navigate-panel="openPanel($event, 'userList')" :user="currentUser" :back-button="shouldShowBackButton" />
        </account-control-panel>

        <account-control-panel v-if="openPanelString === 'new'" @close-panel="closePanel" action-name="Invite New User">
          <account-new @navigate-panel="openPanel($event, 'userList')" />
        </account-control-panel>
      </transition>
      <div class="header-buttons" id="header-buttons">
        <button v-if="isCurrentUserAgent" class="app-icon-button tooltip-bottom tooltip-light" id="headheadway-changelog" data-tooltip="What's new?">
          <app-icon type="svg" name="flash" />
        </button>
        <a href="//help.double.giving" target="_blank" class="app-icon-button tooltip-bottom tooltip-light" data-tooltip="Support">
          <app-icon type="font" name="help-circle" />
        </a>

        <button class="app-icon-button tooltip-bottom tooltip-light" data-tooltip="Notifications">
          <app-icon type="font" name="notifications" />
        </button>

        <button v-popover:account class="app-icon-button tooltip-bottom tooltip-light" data-tooltip="Account">
          <app-icon type="font" name="person" />
        </button>
        <account-popover @open-manager-panel="openManagerPanel" @open-edit-panel="openPanel('edit')" />
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
.app-header {
  padding: 16px 58px;

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #1e2240;
    opacity: 0.7;
    z-index: 10;
    transition: 0.15s ease-out;
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .header-buttons {
      display: flex;
      align-items: center;

      #headheadway-changelog {
        ::v-deep .HW_badge_cont {
          position: absolute;
          left: -9px;
          top: -6px;
          padding: 0 0 60px 50px;
        }
      }

      &:hover .app-icon-button:not(:hover),
      .app-icon-button:focus {
        &::before,
        &::after {
          visibility: hidden;
        }
      }
    }
  }
}

.account-panel-slide-enter-active,
.account-panel-slide-leave-active {
  transition: transform 0.2s ease;
}

.account-panel-slide-enter,
.account-panel-slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}
</style>
