// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import Vue from 'vue';

const requireComponent = import.meta.glob('./[A-Za-z]*.vue');

Object.entries(requireComponent).forEach(async ([path, component]) => {
  // Get the component config
  const componentConfig = await component();
  // Get the PascalCase version of the component name
  const componentName = path
    // Remove the "./" from the beginning
    .replace(/^\.\//, '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '')
    // Convert the filename to PascalCase
    .replace(/(-\w)/g, (matches) => matches[1].toUpperCase())
    .replace(/^\w/, (matches) => matches.toUpperCase());

  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig);
});
