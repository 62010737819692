import axios from 'axios';

const _request = axios.create({
  baseURL: import.meta.env.VITE_APP_SERVER_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  validateStatus: () => true // Only throw for network errors - not for HTTP status codes
});

async function axiosWrapper(method, url, data, config) {
  const reply = await _request({
    method,
    url,
    data,
    ...config
  });
  return {
    error: reply.data.status === 'success' ? undefined : { code: reply.status, message: reply.data.data },
    data: reply.data.data
  };
}

async function request(...args) {
  return axiosWrapper('get', ...args);
}

['post', 'put', 'get', 'delete'].forEach((method) => {
  request[method] = async function (...args) {
    return axiosWrapper(method, ...args);
  };
});

request.fileUpload = async function (method, url, formData) {
  return axiosWrapper(method, url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default request;
