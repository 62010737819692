// import _ from 'lodash';
import request from '../../services/request';
export const state = {
  users: []
};

export const mutations = {
  SET_USERS(state, newValue) {
    state.users = newValue;
  }
};

export const actions = {
  async createUser({ dispatch, rootState }, userObject) {
    const orgId = rootState.auth.currentUser.orgId;
    if (!orgId) {
      throw new Error('Org id is missing');
    }
    const { data, error } = await request.post(`/org/${orgId}/agent/`, { agent: userObject });
    if (error) {
      throw new Error(data);
    } else {
      dispatch('fetchUsers');
    }
  },

  async saveUser({ dispatch }, { form }) {
    const { data, error } = await request.put(`/user`, { form });
    if (error) {
      throw new Error(data);
    } else {
      const user = removePasswords(data); // Mainly necessary for not puplating the form with the password values on a re-edit
      dispatch('auth/setCurrentUser', user, { root: true });
      return data;
    }
  },

  async deleteUser({ dispatch, rootGetters }, { userId }) {
    if (!rootGetters['auth/isCurrentUserAdmin']) {
      throw new Error('Permission denied!');
    }
    const { data, error } = await request.delete(`/user/${userId}`);
    if (error) {
      throw new Error(data);
    } else {
      dispatch('fetchUsers');
    }
  },

  async fetchUsers({ commit, rootState }) {
    const orgId = rootState.auth.currentUser.orgId;
    if (!orgId) {
      throw new Error('Org id is missing');
    }
    const { data, error } = await request.get(`/org/${orgId}/user`);
    if (error) {
      throw new Error(data);
    } else {
      commit('SET_USERS', data.rows);
      return data;
    }
  }
};

function removePasswords(userObject) {
  const user = { ...userObject };
  const passwordFieldKeys = ['password', 'password2', 'currentPassword'];
  passwordFieldKeys.forEach((field) => delete user[field]);
  return user;
}
