import request from '../../services/request';

export const actions = {
  async fundraiserCreate(_, { form, avatar }) {
    const bodyFormData = new FormData();
    bodyFormData.append('fundraiser', JSON.stringify(form));
    bodyFormData.append('avatar', avatar);

    const { data, error } = await request.fileUpload('post', '/fundraiser', bodyFormData);
    if (error) {
      throw new Error(data);
    }
    return data;
  },

  async fundraiserUpdate(_, { form, userId, avatar, deleteAvatar = false }) {
    const bodyFormData = new FormData();
    bodyFormData.append('fundraiser', JSON.stringify(form));
    bodyFormData.append('avatar', avatar);

    if (deleteAvatar) {
      bodyFormData.append('deleteAvatar', deleteAvatar);
    }

    const { data, error } = await request.fileUpload('put', `/fundraiser/${userId}`, bodyFormData);

    if (error) {
      throw new Error(data);
    }
    return data;
  },

  fundraiserApprove(_, { fundraiserId }) {
    try {
      return request.post(`/fundraiser/${fundraiserId}/approve`);
    } catch {
      throw new Error('Something went wrong');
    }
  },

  async fundraiserSignupCount() {
    const { data, error } = await request.get('/fundraiser-signup/count');
    if (error) {
      throw new Error(data);
    }
    return data;
  }
};
