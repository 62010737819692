import rightArrowSvg from './right-arrow-svg';

const utils = {
  rightArrowSvg,
  getCurrencySymbol(currency) {
    if (!currency) {
      return '$';
    }

    const currencySymbols = {
      usd: '$',
      eur: '€',
      gbp: '£',
      cad: 'C$',
      aud: 'A$'
    };

    const symbol = currencySymbols[currency.toLowerCase()] || '';
    if (!symbol) {
      throw new Error('Unknown currency');
    }

    return symbol;
  },

  frequencyLabels: {
    single: 'One-time',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly'
  },

  wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
};

export default utils;
