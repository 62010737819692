<script>
import _ from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';
import categories from '@/services/orgCategories';
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'AccountManage',
  components: {
    ContentLoader
  },
  props: {
    activeTabProp: {
      type: String,
      default() {
        this.activeTabOptions[0]; // `this` can't be accessed here...
      },
      validator(value) {
        return () => this.activeTabOptions.includes(value);
      }
    }
  },
  data() {
    return {
      agentDeleteProgress: false,
      saveOrgState: null, //'valid' | 'invalid' | 'processing' | 'success' | 'error'
      activeTabOptions: ['orgInfo', 'userList'],
      activeTab: this.activeTabProp,
      org: {},
      orgBeforeUpdate: {},
      isFetchingInProgress: false,
      logoFile: null,
      shouldDeleteLogo: false
    };
  },
  computed: {
    ...mapGetters('user', ['getAgentPermission']),
    ...mapState('user', ['users']),
    ...mapGetters('auth', ['currentUser', 'isCurrentUserSuperuser', 'isCurrentUserOwner', 'isCurrentUserAdmin']),
    ...mapGetters('org', ['getOrg']),
    currentOrg() {
      return this.getOrg;
    },
    usersSorted() {
      if (!this.users.length) {
        return;
      }

      return this.sortAgents(this.users);
    },
    orgCategoriesObjects() {
      return categories.reduce((object, key) => ({ ...object, [key]: key }), {});
    },
    saveOrgButtonState() {
      switch (this.saveOrgState) {
        case 'invalid':
          return 'disabled';
        case 'processing':
          return 'loading';
      }
      if (!this.isOrgUpdated && !this.shouldDeleteLogo && !this.logoFile) {
        return 'disabled';
      } else {
        return 'enabled';
      }
    },
    isOrgUpdated() {
      return !_.isEqual(this.org, this.orgBeforeUpdate);
    },
    logoName() {
      return _.get(this.org, 'crmSettings.logoName');
    },
    logoPath() {
      return `${import.meta.env.VITE_APP_SERVER_URL}/public-files/org/${this.org.id}/${this.logoName}?${Date.now()}`;
    },

    isFormulateRemoveButtonAvailable() {
      if (!this.$refs.fileUploader) {
        return;
      }

      return !!this.$refs.fileUploader.$el.querySelector('.formulate-file-remove');
    }
  },

  methods: {
    ...mapActions('user', ['fetchUsers', 'deleteUser']),
    ...mapActions('org', ['saveOrg']),
    async saveOrgClicked() {
      const state = this.saveOrgState;
      if (state === 'processing' || state === 'invalid') {
        return;
      }

      this.saveOrgState = 'processing';
      try {
        // delete this.org._logoFile; // Formulate adds it automativcally because it's inside a form
        await this.saveOrg({
          org: this.org,
          orgLogo: this.logoFile,
          deleteLogo: this.shouldDeleteLogo
        });
        this.saveOrgState = 'success';
        this.$notify({ text: 'Successfully saved changes', type: 'success' });
        this.$emit('remount-component'); // Force a component rerender to initialize the state
      } catch (err) {
        console.log(err);
        this.saveOrgState = 'error';
        this.formErrorMessages = [err.message];
      } finally {
        this.orgBeforeUpdate = _.cloneDeep(this.currentOrg);
      }
    },
    async deleteUserClicked(agent) {
      this.$modal.show('dialog', {
        title: `Delete ${agent.firstName} ${agent.lastName}?`,
        text: '<div class="content">Are you sure you want to delete this user?</div>',
        buttons: [
          {
            title: 'Cancel',
            class: 'button-cancel',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: 'Delete',
            class: 'button-primary',
            handler: async () => {
              await this.deleteUser({ userId: agent.id });
              try {
                this.agentDeleteProgress = true;
                this.$notify({
                  text: 'Successfully deleted user',
                  type: 'success'
                });
                this.$modal.hide('dialog');
              } catch (e) {
                this.$notify({
                  text: 'User deletion failed',
                  type: 'error'
                });
                console.log(e);
              } finally {
                this.agentDeleteProgress = false;
              }
            }
          }
        ]
      });
      if (this.agentDeleteProgress) {
        return;
      }
    },

    sortAgents(agentList) {
      const agents = [...agentList].sort((a, b) => {
        return a.createdAt.localeCompare(b.createdAt);
      });
      const currentUserIndex = agents.findIndex((agent) => agent.id === this.currentUser.id);
      const currentUser = agents.find((agent) => agent.id === this.currentUser.id);
      if (currentUserIndex !== -1) {
        // when a superuser is logged in as user he won't show up in the agent list
        agents.splice(currentUserIndex, 1);
        agents.unshift(currentUser);
      }
      return agents;
    },
    agentNameFormatted({ firstName, lastName }) {
      return `${firstName} ${lastName}`;
    },
    canEditAccount(agent) {
      const isOwnAccount = this.currentUser.id === agent.id;
      return isOwnAccount;
    },
    canDeleteAccount(agent) {
      const isOwnAccount = this.currentUser.id === agent.id;
      if (isOwnAccount) {
        return false;
      }

      const canDeleteAsAdmin = this.isCurrentUserAdmin && agent.permission === 'agent';
      return this.isCurrentUserSuperuser || this.isCurrentUserOwner || canDeleteAsAdmin;
    },

    isCurrentUser(userId) {
      return userId === this.currentUser.id;
    },
    isAdmin(permissionRole) {
      return permissionRole === 'superuser' || permissionRole === 'admin';
    },
    permissionFormatted(agent) {
      const permission = agent.user_orgs[0].permission;
      const permissionString = permission === 'agent' ? 'member' : permission;
      const capitalizeFilter = this.$options.filters.capitalize;
      return `${capitalizeFilter(permissionString)} ${this.isCurrentUser(agent.id) ? '(You)' : ''}`;
    },
    isNotActive(agent) {
      return agent.active === false;
    }
  },

  async created() {
    this.org = this.currentOrg;
    this.orgBeforeUpdate = _.cloneDeep(this.org);
    this.showLogo = !!_.get(this.org, 'crmSettings.logoName');
    try {
      this.isFetchingInProgress = true;
      await this.fetchUsers();
      this.isFetchingInProgress = false;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<template>
  <section class="account-manage content">
    <div class="inner-container">
      <app-tabs :active-tab-prop="activeTab" @activeTabChanged="activeTab = $event">
        <app-tab name="orgInfo" title="Organization">
          <div>
            <formulate-form @submit="saveOrgClicked" id="org-info-form" class="org-info-form space-y-4">
              <file-input @file-uploaded="logoFile = $event" label="Image" :file-url="logoPath" @delete-file="shouldDeleteLogo = true" />
              <formulate-input v-model="org.name" type="text" label="Name" name="name" validation="required" class="text-xs" />
              <formulate-input
                v-model="org.mainCategory"
                :options="orgCategoriesObjects"
                type="vue-select"
                name="mainCategory"
                validation="required"
                validation-name="Main category"
                placeholder="Select"
                :searchable="false"
                label="Main Category"
              />
              <formulate-input
                v-model="org.website"
                type="text"
                label="Website Address"
                name="website"
                validation="required"
                validation-name="Website Address"
                class="text-xs"
              />
            </formulate-form>
          </div>
        </app-tab>
        <app-tab name="userList" title="Teammates">
          <div>
            <div v-if="!usersSorted && isFetchingInProgress">
              <div v-for="n in 3" class="agent-container" :key="n">
                <content-loader primary-color="#f5f7fc" secondary-color="#e4e6f5" :speed="1" class="content-loader" />
              </div>
            </div>
            <div v-else-if="!usersSorted" class="no-users">No users</div>
            <div v-else v-for="agent in usersSorted" :key="agent.id" class="agent-container">
              <div class="agent-panel">
                <div>
                  <label v-if="isNotActive(agent)" class="label-error">Not active</label>
                  <h3 :style="isNotActive(agent) ? 'color: var(--purple-light-3)' : ''" class="agent-name">
                    {{ agentNameFormatted(agent) }}
                  </h3>
                  <h5 class="agent-permission">
                    {{ permissionFormatted(agent) }}
                  </h5>
                </div>
                <div class="icons">
                  <span @click="$emit('account-edit')" v-if="canEditAccount(agent)">
                    <app-icon name="edit-pen" type="svg" class="icon" />
                  </span>
                  <span v-if="canDeleteAccount(agent)" @click="deleteUserClicked(agent)"><ion-icon name="trash-outline" class="icon" /></span>
                </div>
              </div>
              <!-- <p class="last-login"p>Last Login: 01/01/2020, 10:32</p> -->
            </div>
          </div>
        </app-tab>
      </app-tabs>
    </div>
    <div class="panel-footer">
      <app-primary-button v-if="activeTab === 'orgInfo'" :state="saveOrgButtonState" type="submit" form="org-info-form" text="Save Changes" size="md" />
      <app-primary-button v-else-if="activeTab === 'userList'" @click="$emit('navigate-panel', 'new')" text="Invite New User" size="md" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.account-manage {
  .nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: var(--purple-light);
    border-bottom: 1px solid var(--purple-light);

    .nav-item {
      padding: 6px 12px;
      width: 50%;
      text-align: center;
      font-size: 13px;
      cursor: pointer;
      user-select: none;

      &.active {
        color: var(--primary-color);
        font-weight: 600;
      }
    }

    &::after {
      content: '';
      background: var(--primary-color);
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50%;
      transition: left 0.2s ease-out;
    }

    &.selected-tab-1::after {
      left: 50%;
    }
  }

  ::v-deep {
    .app-tab {
      input,
      select {
        width: 100%;
      }

      .org-info-form {
        margin-top: 32px;

        .vs__selected {
          margin-top: 40px;
        }
      }

      .agent-container {
        margin: 24px 0;

        .content-loader {
          border-radius: 10px;
        }

        .agent-panel {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;
          color: var(--primary-color);
          background: var(--gray-light-2);
          border-radius: 10px;
        }

        .agent-name,
        .agent-permission {
          margin: 0;
        }

        .agent-name {
          font-size: 16px;
          font-weight: 700;
          line-height: 32px;
        }

        .agent-permission {
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
          color: var(--purple-light-3);
        }

        .last-login {
          margin: 8px 0;
          font-size: 11px;
          line-height: 10px;
          color: var(--purple-light-3);
        }
      }
    }
  }

  .no-users {
    margin: 36px 18px;
    color: var(--purple-light-3);
    font-size: 13px;
  }

  .icons {
    display: flex;
    align-items: center;

    > * {
      margin: 2px;
    }
  }

  .icon {
    color: var(--purple-light);
    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover {
      color: var(--blue-light-2);
    }

    &:active {
      color: var(--primary-color);
    }
  }

  [name='trash-outline'] {
    &:hover {
      color: var(--red);
    }
  }

  ::v-deep .svg-icon {
    &:hover {
      svg g {
        stroke: var(--primary-color);
      }
    }
  }

  .control:hover {
    [name='trash-outline'] {
      color: var(--red);
    }

    ::v-deep .svg-icon {
      svg g {
        stroke: var(--primary-color);
      }
    }
  }
}
</style>
