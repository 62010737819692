import _ from 'lodash';
import dayjs from 'dayjs';
import request from '../../services/request';

export const state = {};

export const mutations = {};

export const actions = {
  // Fetches last 2 years
  async fetchRecentAnalytics({ rootState }) {
    const orgId = rootState.auth.currentUser.orgId;
    if (!orgId) {
      throw new Error('Org id is missing');
    }
    const { data, error } = await request.get(`/org/${orgId}/recent-metrics`);
    if (error) {
      throw new Error(data);
    } else {
      const analyticsData = mapData(data);
      return analyticsData;
    }
  },

  async getMrr({ rootState }) {
    const orgId = rootState.auth.currentUser.orgId;
    if (!orgId) {
      throw new Error('Org id is missing');
    }
    const { data, error } = await request.get(`/org/${orgId}/mrr`);
    if (error) {
      throw new Error(data);
    } else {
      return data;
    }
  }
};

// Helper functions
function mapData(data, startDateOptional) {
  const types = ['charges', 'donations', 'donors'];
  const mapped = {};

  types.forEach((type) => {
    // Always return labels for the last 2 years at least
    const currentYear = dayjs().year();
    const labels = [currentYear, currentYear - 1];
    if (startDateOptional) {
      const yearsToAdd = range(startDateOptional.year(), currentYear - 2).reverse();
      labels.push(...yearsToAdd);
    }

    const entry = {};
    labels.forEach((label) => {
      const list = data[type];
      const months = Array(12).fill();
      entry[label] = months.map(arrayIndexToMonthSumMapper(list, label));
    });
    mapped[type] = entry;
  });

  if (data.mrr) {
    mapped.mrr = data.mrr;
  }
  return mapped;
}

function arrayIndexToMonthSumMapper(list, year) {
  return (x, index) => {
    const entry = list.find((metric) => metric.month === index + 1 && metric.year === year);
    return _.get(entry, 'sum') || 0;
  };
}

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}
