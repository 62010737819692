<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'AccountEdit',
  props: {
    backButton: Boolean,
    user: Object
  },

  data() {
    return {
      form: {
        user: {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          currentPassword: '',
          password: '',
          password2: ''
        },
        emailSettings: {
          chargeSucceeded: this.user.userOrg?.emailSettings?.chargeSucceeded,
          chargeFailed: this.user.userOrg?.emailSettings?.chargeFailed
        }
      },
      formBeforeUpdate: {},
      password: {},
      showPasswordCurrent: false,
      showPassword: false,
      showPassword2: false,
      isShowPasswordSettings: false,
      //!TODO: rename to `formState`, should be an app convention, + clean up naming mess
      saveSettingsState: null, //'valid' | 'invalid' | 'processing'
      formErrorMessages: []
    };
  },

  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapGetters('auth', ['isCurrentUserAdmin']),
    ...mapState('user', ['users']),

    isOwnUser() {
      return this.currentUser.id === this.form.user.id;
    },
    buttonState() {
      switch (this.saveSettingsState) {
        case 'valid':
          return 'enabled';
        case 'invalid':
          return 'disabled';
        case 'processing':
          return 'loading';
      }
      if (!this.isSettingsUpdated) {
        return 'disabled';
      } else {
        return 'enabled';
      }
    },
    isSettingsUpdated() {
      return !_.isEqual(this.form, this.formBeforeUpdate);
    }
  },

  watch: {
    form: {
      async handler() {
        this.formErrorMessages = [];
        const isFormValid = !(await this.formHasErrors());
        if (!isFormValid) {
          this.saveSettingsState = 'invalid';
        } else {
          this.saveSettingsState = 'valid';
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('user', ['saveUser']),
    ...mapActions('auth', ['signIn']),
    async formHasErrors() {
      return this.$refs.accountEditForm.hasValidationErrors();
    },
    async saveUserClicked() {
      const state = this.saveSettingsState;
      if (state === 'processing' || state === 'invalid') {
        return;
      }

      this.saveSettingsState = 'processing';
      const isPasswordChanged = !!this.form.user.password;
      try {
        const res = await this.saveUser({ form: this.form });
        this.$notify({ text: 'Successfully saved changes', type: 'success' });
        if (this.isOwnUser && isPasswordChanged) {
          // Create immediately a new session
          await this.signIn({
            email: res.email,
            password: this.user.password
          });
        }
      } catch (err) {
        this.formErrorMessages = [err.message];
      } finally {
        this.saveSettingsState = 'invalid';
        this.formBeforeUpdate = this.user;
      }
    }
  },

  mounted() {
    this.formBeforeUpdate = _.cloneDeep(this.form);
  }
};
</script>

<template>
  <section class="account-edit content">
    <formulate-form class="form" @submit="saveUserClicked" ref="accountEditForm" :form-errors="formErrorMessages">
      <div class="inner-container space-y-10">
        <div class="space-y-5">
          <div class="title-container">
            <ion-icon name="person" class="icon"></ion-icon>
            <h3 class="title">User Info</h3>
          </div>
          <formulate-input
            v-model="form.user.firstName"
            name="firstName"
            type="text"
            validation="required"
            validation-name="First name"
            label="First name"
            placeholder="John"
          />
          <formulate-input
            v-model="form.user.lastName"
            name="lastName"
            type="text"
            validation="required"
            validation-name="Last name"
            label="Last name"
            placeholder="Doe"
          />
          <formulate-input
            v-model="form.user.email"
            name="email"
            type="email"
            validation="^required|email"
            validation-name="Email"
            label="Email address"
            placeholder="John@doe.com"
          />

          <div class="change-password">
            <button @click="isShowPasswordSettings = true" v-if="!isShowPasswordSettings" type="button" class="change-password-button">Change Password</button>
            <div v-if="isShowPasswordSettings" class="space-y-4">
              <div class="title-container">
                <ion-icon name="lock-closed" class="icon"></ion-icon>
                <h3 class="title">Set New Password</h3>
              </div>
              <formulate-input
                v-model="form.user.currentPassword"
                validation="^required|min:6,length"
                validation-name="Password"
                name="currentPassword"
                :type="showPasswordCurrent ? 'text' : 'password'"
                label="Current password"
                class="password"
              >
                <template #help>
                  <button @click="showPasswordCurrent = !showPasswordCurrent" type="button" class="show-password-toggle">
                    <ion-icon v-if="showPasswordCurrent" name="eye"></ion-icon>
                    <ion-icon v-else name="eye-off"></ion-icon>
                  </button>
                </template>
              </formulate-input>
              <formulate-input
                v-model="form.user.password"
                :type="showPassword ? 'text' : 'password'"
                validation="^required|min:6,length"
                validation-name="Password"
                name="password"
                label="New password"
                class="password"
              >
                <template #help>
                  <button @click="showPassword = !showPassword" type="button" class="show-password-toggle">
                    <ion-icon v-if="showPassword" name="eye"></ion-icon>
                    <ion-icon v-else name="eye-off"></ion-icon>
                  </button>
                </template>
              </formulate-input>
              <formulate-input
                v-model="form.user.password2"
                :type="showPassword2 ? 'text' : 'password'"
                autocomplete="new-password"
                name="password_confirm"
                validation="^required|confirm"
                validation-name="Password confirmation"
                label="Confirm new password"
                class="password"
              >
                <template #help>
                  <button @click="showPassword2 = !showPassword2" type="button" class="show-password-toggle">
                    <ion-icon v-if="showPassword2" name="eye"></ion-icon>
                    <ion-icon v-else name="eye-off"></ion-icon>
                  </button>
                </template>
              </formulate-input>
            </div>
          </div>

          <formulate-errors />
        </div>
        <div>
          <div class="title-container mb-6">
            <ion-icon name="mail" class="text-red-custom w-5 h-5"></ion-icon>

            <h3 class="title">Email notifications</h3>
          </div>
          <div class="space-y-3">
            <formulate-input v-model="form.emailSettings.chargeSucceeded" type="checkbox" name="emailNotifications" label="Successful donations" />
            <formulate-input v-model="form.emailSettings.chargeFailed" type="checkbox" name="emailNotifications" label="Failed donations" />
          </div>
        </div>
      </div>

      <div class="panel-footer">
        <button type="button" v-if="isCurrentUserAdmin && backButton" @click="$emit('navigate-panel', 'manage')" class="button-secondary">Back</button>

        <app-primary-button :state="buttonState" text="Save Changes" size="md" />
      </div>
    </formulate-form>
  </section>
</template>

<style lang="scss" scoped>
.account-edit {
  max-width: 424px;
  z-index: 10;

  .change-password {
    margin: 36px 0;

    .change-password-button {
      padding: 10px 20px;
      font-size: 13px;
      font-weight: 600;
      color: var(--primary-color);
      background: var(--purple-light-4);
      border: 0;
      border-radius: 8px;
    }
  }
}
</style>
